






































































import {Modal} from '@simpli/vue-modal'
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {InputCheckbox} from '@simpli/vue-input'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {FilterExchangeSchema} from '@/schema/resource/Exchange/FilterExchangeSchema'
import {ExchangeCollection} from '@/model/collection/ExchangeCollection'
import {ExchangeSupportedTokens} from '@/model/resource/ExchangeSupportedTokens'

@Component({
  components: {Modal, InputCheckbox},
})
export default class FilterExchangeMobile extends Mixins(MixinScreenSize) {
  @Prop({type: Object, required: true}) collection!: ExchangeCollection

  schema = new FilterExchangeSchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionBlockchainVersion.queryAsPage(),
      this.collection.resource.collectionSupportedTokens.queryAsPage(),
    ]

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  hasValue(list = [], value: number) {
    return list.some((item: never) => item === value)
  }

  async doFilter() {
    this.$emit('submit')
  }

  addBlockchain(param: BlockchainVersion) {
    const blockchains = this.collection.exchangeBlockchainVersion || []

    if (blockchains?.includes(param)) {
      this.collection.exchangeBlockchainVersion = blockchains?.filter(
        blockchain =>
          blockchain.idBlockchainVersionPk !== blockchain.idBlockchainVersionPk
      )
    } else {
      this.collection.exchangeBlockchainVersion = [...blockchains, param]
    }
  }

  addSupportedToken(param: ExchangeSupportedTokens) {
    const supportedTokens = this.collection.exchangeSupportedTokens || []

    if (supportedTokens?.includes(param)) {
      this.collection.exchangeSupportedTokens = supportedTokens?.filter(
        supportedToken => supportedToken.id !== supportedToken.id
      )
    } else {
      this.collection.exchangeSupportedTokens = [...supportedTokens, param]
    }
  }
  doClear() {
    this.collection.exchangeBlockchainVersion = []
    this.collection.exchangeSupportedTokens = []
    this.collection.search = ''
    this.doFilter()
  }
}
