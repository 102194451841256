/**
 * BlockchainVersionCollection
 *
 * @author Simpli CLI generator
 */
import {HttpExclude} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {ExchangeSupportedTokens} from '@/model/resource/ExchangeSupportedTokens'
import {getSupportedTokens} from '@/components/filters/FilterExchange.vue'

/* TODO: review generated class */
@HttpExclude()
export class ExchangeSupportedTokensCollection extends PageCollection<
  ExchangeSupportedTokens
> {
  constructor() {
    super(ExchangeSupportedTokens)
  }

  resource?: IBlockchainVersionCollectionResourcesHolder

  async queryAsPage() {
    return this.listSupportedTokens()
  }

  async listSupportedTokens() {
    getSupportedTokens.map(item => {
      this.items.push(new ExchangeSupportedTokens(item.id, item.title))
    })

    return this
  }
}

export interface IBlockchainVersionCollectionResourcesHolder {}
