/**
 * BlockchainVersion
 * @author Simpli CLI generator
 */
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {$} from '@/facade'

/* TODO: review generated class */
export class ExchangeSupportedTokens implements IResource {
  constructor(id: number, title: string) {
    this.id = id
    this.title = $.t(title) as string
  }

  id: number = 0
  title: string | null = null

  get $id() {
    return this.id
  }

  set $id(val) {
    this.id = val
  }

  get $tag() {
    return String(this.title)
  }
}
