/**
 * BlockchainVersion
 * @author Simpli CLI generator
 */
import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'

/* TODO: review generated class */
export class BlockchainVersion implements IResource {
  idBlockchainVersionPk: number = 0

  title: string | null = null
  color: string | null = null
  networkIdentifier: string | null = null
  active: boolean | null = null
  tagName: string | null = null

  get $id() {
    return this.idBlockchainVersionPk
  }

  set $id(val) {
    this.idBlockchainVersionPk = val
  }

  get $tag() {
    return String(this.title)
  }

  get $tagName() {
    return this.tagName ?? this.title ?? ''
  }

  get formattedColor() {
    return `background-color: ${this.color}`
  }

  /**
   * Lists the instances of BlockchainVersion
   */
  static async listBlockchainVersion(params: any) {
    return await Request.get(`/client/blockchain-version`, {params})
      .name('listBlockchainVersion')
      .as(BlockchainVersionCollection)
      .getData()
  }

  /**
   * Lists the instances of BlockchainVersion to export as a file
   */
  static async listExportBlockchainVersion(params: any) {
    return await Request.get(`/client/blockchain-version/export`, {params})
      .name('listExportBlockchainVersion')
      .as(BlockchainVersionCollection)
      .getData()
  }

  /**
   * Gets a instance of a given ID of BlockchainVersion
   */
  async getBlockchainVersion(id: number) {
    return await Request.get(`/client/blockchain-version/${id}`)
      .name('getBlockchainVersion')
      .as(this)
      .getData()
  }

  /**
   * Deletes a instance of a given ID of BlockchainVersion
   */
  async removeBlockchainVersion() {
    return await Request.delete(`/client/blockchain-version/${this.$id}`)
      .name('removeBlockchainVersion')
      .asNumber()
      .getData()
  }

  /**
   * Persists a new instance of BlockchainVersion. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistBlockchainVersion() {
    return await Request.post(`/client/blockchain-version`, this)
      .name('persistBlockchainVersion')
      .asNumber()
      .getData()
  }
}
