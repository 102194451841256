







































import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {ExchangeCollection} from '@/model/collection/ExchangeCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {ExchangeSupportedTokens} from '@/model/resource/ExchangeSupportedTokens'

@Component
export default class FilterExchangeFiltered extends Vue {
  @Prop({type: Object, required: true}) collection!: ExchangeCollection

  get hasFilteredItems() {
    return this.blockchainVersions?.length || this.supportedTokens?.length
  }

  get blockchainVersions() {
    return this.collection.exchangeBlockchainVersion
  }

  get supportedTokens() {
    return this.collection.exchangeSupportedTokens
  }

  @Emit('filter')
  clearBlockchainVersion(blockchainVersion: BlockchainVersion) {
    const {exchangeBlockchainVersion} = this.collection
    this.collection.exchangeBlockchainVersion =
      exchangeBlockchainVersion?.filter(
        item => item.$id !== blockchainVersion.$id
      ) || []
  }

  @Emit('filter')
  clearSupportedTokens(supportedTokens: ExchangeSupportedTokens) {
    const {exchangeSupportedTokens} = this.collection
    this.collection.exchangeSupportedTokens =
      exchangeSupportedTokens?.filter(
        item => item.$id !== supportedTokens.$id
      ) || []
  }

  @Emit('filter')
  doClear() {
    this.collection.exchangeBlockchainVersion = []
    this.collection.exchangeSupportedTokens = []
  }
}
