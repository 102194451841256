/**
 * Filter Schema of Exchange
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IExchangeCollectionResourcesHolder} from '@/model/collection/ExchangeCollection'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {Exchange} from '@/model/resource/Exchange'
import {ExchangeSupportedTokensCollection} from '@/model/collection/ExchangeSupportedTokensCollection'

/* TODO: review generated schema */
export class FilterExchangeSchema extends DefaultSchema
  implements IExchangeCollectionResourcesHolder {
  collectionBlockchainVersion = new BlockchainVersionCollection().noPagination()
  collectionSupportedTokens = new ExchangeSupportedTokensCollection().noPagination()
  readonly name = 'FilterExchange'

  readonly fieldSet: FieldSet<Exchange> = {
    exchangeBlockchainVersion: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.collectionBlockchainVersion.items,
        multiple: true,
        closeOnSelect: false,
        preserveSearch: true,
        placeholder: this.translateFrom(schema.fieldName),
        trackBy: '$id',
        preselectFirst: true,
      },
    }),
    exchangeSupportedTokens: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.collectionSupportedTokens.items,
        multiple: true,
        closeOnSelect: false,
        preserveSearch: true,
        placeholder: this.translateFrom(schema.fieldName),
        trackBy: '$id',
      },
    }),
  }
}
