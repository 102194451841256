






























































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {FilterExchangeSchema} from '@/schema/resource/Exchange/FilterExchangeSchema'
import {ExchangeCollection} from '@/model/collection/ExchangeCollection'
import FilterExchangeFiltered from '@/components/filters/FilterExchangeFiltered.vue'
import FilterExchangeMobile from '@/components/filters/FilterExchangeMobile.vue'

export const getSupportedTokens = [
  {
    id: 1,
    title: 'schema.FilterExchange.supportsNeo',
  },
  {
    id: 2,
    title: 'schema.FilterExchange.supportsGas',
  },
  {
    id: 3,
    title: 'schema.FilterExchange.supportsNep17',
  },
]

@Component({
  components: {
    FilterExchangeFiltered,
    FilterExchangeMobile,
  },
})
export default class FilterExchange extends Mixins(MixinScreenSize) {
  @Prop({type: Object, required: true}) collection!: ExchangeCollection

  schema = new FilterExchangeSchema()
  showModal: boolean = false

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionBlockchainVersion.queryAsPage(),
      this.collection.resource.collectionSupportedTokens.queryAsPage(),
    ]

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  async doFilter() {
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.queryToFirstPage())
    this.showModal = false
  }

  doClear() {
    this.collection.exchangeBlockchainVersion = []
    this.collection.exchangeSupportedTokens = []
    this.doFilter()
  }

  inputClass(): string {
    return 'multiselect--hide-tags'
  }

  private toggleModal(): void {
    this.showModal = !this.showModal
  }
}
