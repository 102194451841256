/**
 * Exchange
 * @author Simpli CLI generator
 */
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {ExchangeCollection} from '@/model/collection/ExchangeCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

/* TODO: review generated class */
export class Exchange implements IResource {
  idExchangePk: number = 0

  title: string | null = null
  imageUrl: string | null = null
  websiteUrl: string | null = null
  order: number | null = null
  active: boolean | null = null

  supportsNeo: boolean | null = null
  supportsGas: boolean | null = null
  supportsNep17: boolean | null = null

  @ResponseSerialize(BlockchainVersion)
  blockchainVersion: BlockchainVersion[] = []

  get $id() {
    return this.idExchangePk
  }
  set $id(val) {
    this.idExchangePk = val
  }
  get $tag() {
    return String(this.title)
  }

  get blockchainVersionTitles() {
    return this.blockchainVersion?.map(resource => resource.title).join(', ')
  }

  /**
   * Gets a instance of a given ID of Exchange
   */
  async getExchange(id: number) {
    return await Request.get(`/client/exchange/${id}`)
      .name('getExchange')
      .as(this)
      .getData()
  }

  /**
   * Deletes a instance of a given ID of Exchange
   */
  async removeExchange() {
    return await Request.delete(`/client/exchange/${this.$id}`)
      .name('removeExchange')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of Exchange
   */
  static async listExchange(params: any) {
    return await Request.get(`/client/exchange`, {params})
      .name('listExchange')
      .as(ExchangeCollection)
      .getData()
  }

  /**
   * Persists a new instance of Exchange. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistExchange() {
    return await Request.post(`/client/exchange`, this)
      .name('persistExchange')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of Exchange to export as a file
   */
  static async listExportExchange(params: any) {
    return await Request.get(`/client/exchange/export`, {params})
      .name('listExportExchange')
      .as(ExchangeCollection)
      .getData()
  }

  /**
   * Increments the order of Exchange
   */
  async changeOrder() {
    return await Request.post(`/client/exchange/${this.$id}/order`, this)
      .name('changeOrder')
      .asNumber()
      .getData()
  }
}
