/**
 * ExchangeCollection
 *
 * @author Simpli CLI generator
 */
import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {Exchange} from '@/model/resource/Exchange'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {ExchangeSupportedTokensCollection} from '@/model/collection/ExchangeSupportedTokensCollection'
import {getSupportedTokens} from '@/components/filters/FilterExchange.vue'

/* TODO: review generated class */
@HttpExclude()
export class ExchangeCollection extends ExpansibleCollection<Exchange> {
  constructor() {
    super(Exchange)
  }

  resource?: IExchangeCollectionResourcesHolder

  @RequestExpose() idBlockchainVersionFk: number[] = []
  @RequestExpose() title: string | null = null
  @RequestExpose() imageUrl: string | null = null
  @RequestExpose() websiteUrl: string | null = null
  @RequestExpose() active: Boolean | null = null
  @RequestExpose() supportsNeo: Boolean | null = null
  @RequestExpose() supportsGas: Boolean | null = null
  @RequestExpose() supportsNep17: Boolean | null = null

  idSupportedTokens: number[] = []

  get exchangeSupportedTokens() {
    return (
      this.resource?.collectionSupportedTokens.getManyIds(
        this.idSupportedTokens
      ) ?? null
    )
  }

  set exchangeSupportedTokens(input) {
    this.idSupportedTokens = input?.map(item => item?.$id) ?? []
    this.clearSupportedTokenList()
    this.addSupportedTokenList()
  }

  addSupportedTokenList() {
    this.idSupportedTokens.forEach(id => {
      if (id === getSupportedTokens[0].id) {
        this.supportsNeo = true
        return
      }
      if (id === getSupportedTokens[1].id) {
        this.supportsGas = true
        return
      }
      if (id === getSupportedTokens[2].id) {
        this.supportsNep17 = true
        return
      }
    })
  }

  clearSupportedTokenList() {
    this.supportsNeo = null
    this.supportsGas = null
    this.supportsNep17 = null
  }

  get exchangeBlockchainVersion() {
    return (
      this.resource?.collectionBlockchainVersion.getManyIds(
        this.idBlockchainVersionFk
      ) ?? null
    )
  }

  set exchangeBlockchainVersion(input) {
    this.idBlockchainVersionFk = input?.map(item => item?.$id) ?? []
  }

  get filterCount(): number {
    return [
      this.idBlockchainVersionFk.length,
      this.idSupportedTokens.length,
      this.search ? 1 : 0,
    ].reduce((sum, value) => (sum += value), 0)
  }

  async queryAsPage() {
    return this.listExchange()
  }

  async listExchange() {
    return await Request.get(`/client/exchange`, {params: this.params})
      .name('listExchange')
      .as(this)
      .getResponse()
  }

  async listExportExchange() {
    return await Request.get(`/client/exchange/export`, {params: this.params})
      .name('listExportExchange')
      .as(this)
      .getResponse()
  }
}

export interface IExchangeCollectionResourcesHolder {
  collectionBlockchainVersion: BlockchainVersionCollection
  collectionSupportedTokens: ExchangeSupportedTokensCollection
}
